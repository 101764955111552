import React from "react";

class MyMap extends React.PureComponent {
  render() {
    return (
      <iframe
        title="Great Wall Map"
        style={{ height: `40vh`, width: `80%` }}
        src="https://maps.google.com/maps?q=Great%20Wall%20Cuisine&t=&z=17&ie=UTF8&iwloc=&output=embed"
      ></iframe>
    );
  }
}

export default MyMap;
