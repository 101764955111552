import React, { Component } from "react";

export default class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  componentDidMount() {
    document.addEventListener("scroll", this.toggleVisibility);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.toggleVisibility);
  }

  toggleVisibility() {
    if (window.pageYOffset > 700) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { is_visible } = this.state;
    return (
      <div className="scroll-to-top">
        {is_visible && (
          <div onClick={() => this.scrollToTop()}>
            <svg xmlns="http://www.w3.org/2000/svg" height="64" width="64">
              <g transform="translate(-18.121 -3.364)">
                <circle cx="50" cy="35" r="32" fill="#2b2b2b" />
                <g transform="translate(14.5 13)">
                  <g fill="#E8C189">
                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                    <path d="M22.435 17.62l4.684 4.685 5.044-5.044v19.352h6.625V17.26l5.044 5.044 4.683-4.684-13.04-13.04z" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    );
  }
}
