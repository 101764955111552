import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/Header.css";
import "./assets/css/Gallery.css";
import "./assets/css/App.css";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

//Route view pages
import Landing from "./components/views/Landing/Landing";
import Menu from "./components/views/Menu/Menu";
// import Reservation from "./components/views/Reservation/Reservation";
import Gallery from "./components/views/Gallery/Gallery";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Landing {...props} />} />
      <Route
        path="/landing-page"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route path="/menu-page" exact render={(props) => <Menu {...props} />} />
      {/* <Route
        path="/reservation-page"
        exact
        render={(props) => <Reservation {...props} />}
      /> */}
      <Route
        path="/gallery-page"
        exact
        render={(props) => <Gallery {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
