import React from "react";
import MyMap from "./Map";

import { Container, Row, Col } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <section className="section section-lg bg-primary">
        <Container className="pt-sm pb-sm">
          <Row className="text-center justify-content-center">
            <Col lg="10">
              <h2 className="display-3 text-white">Location</h2>
              <p className="lead text-white">Great Wall Cusine</p>
              <p className="description text-white">
                3446 W Camelback Rd #155 <br></br>
                Phoenix, AZ 85017 <br></br>
                (602) 973-1112
              </p>

              <p className="description text-white">
                Mon - Fri <br></br>
                11AM - 9PM <br></br>
                Sat - Sun <br></br>
                10AM - 9PM <br></br>
              </p>

              <MyMap></MyMap>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Footer;
