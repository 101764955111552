import React from "react";
import Header, { Banner } from "../../common/Header";
import Footer from "../../common/Footer";
import { Container, Row, Col } from "reactstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const images = [
  "Gallery-1.jpg",
  "Gallery-2.jpg",
  "Gallery-3.jpg",
  "Gallery-4.jpg",
  "Gallery-5.jpg",
  "Gallery-6.jpg",
  "Gallery-7.jpg",
  "Gallery-8.jpg",
  "Gallery-9.jpg",
  "Gallery-10.jpg",
  "Gallery-11.jpg",
  "Gallery-12.jpg",
  "Gallery-13.jpg",
  "Gallery-15.jpg",
  "Gallery-16.jpg",
  "Gallery-17.jpg",
  "Gallery-18.jpg",
  "Gallery-19.jpg",
  "Gallery-20.jpg",
  "Gallery-21.jpg",
  "Gallery-22.jpg",
  "Gallery-23.jpg",
];
class Gallery extends React.Component {
  render() {
    return (
      <>
        <Header></Header>
        <Banner
          path="Gallery/Gallery-BG.jpg"
          name="Gallery"
          min="-5"
          color="default"
        ></Banner>
        <section className="section section-sm">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-1" md={{ size: 10, offset: 1 }}></Col>
            </Row>
          </Container>
        </section>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          className="pb-12p"
        >
          <Masonry columnsCount={3} gutter="5px">
            {images.map((image, i) => (
              <div key={i} className="img-hover-zoom">
                <img
                  key={i}
                  src={require(`../../../assets/img/Gallery/${image}`)}
                  style={{ width: "100%", display: "block" }}
                  alt=""
                />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
        <Footer></Footer>
      </>
    );
  }
}
export default Gallery;
