import React from "react";
// nodejs library that concatenates classes

import marked from "marked";
import News1Covid from "../../../news/covid-1.md";

import "react-image-gallery/styles/css/image-gallery.css";

import Header from "../../common/Header";
import Footer from "../../common/Footer";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = { new1: "" };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    fetch(News1Covid)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ new1: marked(text) });
      });
  }
  render() {
    return (
      <>
        <Header name="landing"></Header>
        <main ref="main">
          <div id="landing-bg">
            {/* shape Hero */}
            <section className="section section-lg landing-section fade-in">
              <Container className="py-lg-md d-flex">
                <div className="col opacity-10">
                  <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                      <img
                        alt="..."
                        className="img-fluid floating landing-logo"
                        src={require("../../../assets/img/Logo-sm.png")}
                      />
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("../../../assets/img/Logo-str-2.png")}
                      />
                      <div className="text-center justify-content-center">
                        <h2 className="heading text-primary">
                          3446 W Camelback Rd #155 <br></br>
                          Phoenix, AZ 85017 <br></br>
                          (602) 973-1112
                        </h2>
                        <Row>
                          <Col></Col>
                          <Col>
                            <p className="text-primary">
                              Mon - Fri <br></br>
                              11AM - 9PM <br></br>
                            </p>
                          </Col>
                          <Col>
                            <p className="text-primary">
                              Sat - Sun <br></br>
                              10AM - 9PM <br></br>
                            </p>
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                      <div className="btn-wrapper text-center mt-sm">
                        <Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="primary"
                          href=""
                          disabled
                        >
                          <span className="btn-inner--text" id="tooltip-order">
                            Order Now
                          </span>
                          <UncontrolledTooltip delay={0} target="tooltip-order">
                            Coming Soon!
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/reservation-page"
                        >
                          <span className="btn-inner--text">
                            Make a Reservation
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md={{ size: 10, offset: 1 }}>
                  <div className="pr-md text-center">
                    <p className="heading-title">News</p>
                  </div>
                  <article
                    dangerouslySetInnerHTML={{ __html: this.state.new1 }}
                  ></article>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md={{ size: 10, offset: 1 }}>
                  <div className="pr-md text-center">
                    <p className="heading-title">AUTHENTIC EXPERIENCE</p>
                    <p className="lead">
                      Come taste the best Cantonese food in the heart of
                      Phoenix, Arizona, next to the beautiful campus of Grand
                      Canyon University. <br></br>All dishes are made with
                      passion and the finest ingredients available, by chefs
                      with decades of experience.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bg-secondary">
            <Container>
              <Row className="">
                <Col style={{ padding: "0px" }}>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../../assets/img/Landing-1.jpg")}
                  />
                </Col>
                <Col style={{ padding: "0px" }}>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../../assets/img/Landing-2.jpg")}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-1" md={{ size: 10, offset: 1 }}>
                  <div className="pr-md text-center">
                    <p className="heading-title">PASTRIES</p>
                    <p className="lead">
                      Come taste our pastries made fresh in-house, daily.
                      Perfect to enjoy while dining in or take it to go.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bg-secondary">
            <Container>
              <Row className="">
                <Col style={{ padding: "0px" }}>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../../assets/img/Landing-3.jpg")}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <Footer></Footer>
        </main>
      </>
    );
  }
}

export default Landing;
