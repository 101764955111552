import React from "react";
import { Link } from "react-router-dom";

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";

import { Parallax } from "react-parallax";

// reactstrap components
import {
  UncontrolledCollapse,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export const Banner = (props) => {
  return (
    <Parallax
      blur={{ min: 1 * props.min, max: 15 }}
      bgImage={require(`../../assets/img/${props.path}`)}
      strength={-15}
      className="fade-in"
    >
      <div style={{ height: "40vh" }}>
        <section className="section section-ctr">
          <Container className="py-lg-md d-flex">
            <div className="col opacity-10">
              <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                  <div className="pr-md text-center">
                    <p className={`heading-title text-${props.color}`}>
                      {props.name}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </Parallax>
  );
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    // initialise
    if (this.props.name === "landing") headroom.init();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth < 570 });
  }

  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <>
        <header className="header-global">
          <Navbar
            className={
              this.props.name === "landing"
                ? "navbar-main navbar-transparent headroom"
                : "navbar-main navbar-solid headroom"
            }
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand
                className={isDesktop ? "w-60vw" : "mr-lg-5"}
                to="/"
                tag={Link}
              >
                <img
                  alt="..."
                  src={require("../../assets/img/Logo-str.png")}
                  className={isDesktop ? "w-60vw" : ""}
                  width={isDesktop ? "" : "450"}
                />
              </NavbarBrand>
              <button
                className="navbar-toggler navbar-light"
                id="navbar_global"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-close">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                    <Col xs="lg-2">
                      <Link to="/">
                        <img
                          alt="..."
                          width="15%"
                          src={require("../../assets/img/Logo-sm.png")}
                        />
                        <span className="fa-lg text-secondary ml-10">
                          Great Wall Cuisine
                        </span>
                      </Link>
                    </Col>
                  </Row>
                </div>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <UncontrolledDropdown nav className="mb-sm">
                    <Link to="/menu-page" className="mr-lg-3 glow--hover">
                      <span className="glow-item text-secondary fa-lg">
                        Menu
                      </span>
                    </Link>
                  </UncontrolledDropdown>
                  {/* <UncontrolledDropdown nav className="mb-sm">
                    <Link
                      to="/reservation-page"
                      className="mr-lg-3 glow--hover"
                    >
                      <span className="glow-item text-secondary fa-lg">
                        Reservations
                      </span>
                    </Link>
                  </UncontrolledDropdown> */}
                  <UncontrolledDropdown nav className="mb-sm">
                    <Link to="/gallery-page" className="mr-lg-3 glow--hover">
                      <span className="glow-item text-secondary fa-lg">
                        Gallery
                      </span>
                    </Link>
                  </UncontrolledDropdown>
                  <NavItem className="glow--hover">
                    <NavLink
                      className="nav-link-icon text-secondary mb-sm glow-item"
                      href="https://www.yelp.com/biz/great-wall-cuisine-phoenix"
                      id="tooltip333589071"
                      target="_blank"
                    >
                      <i className="fa fa-yelp text-secondary" />
                      <span className="nav-link-inner--text d-lg-none ml-2 text-secondary fa-lg">
                        Yelp
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589071">
                      Check us out on Yelp
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="glow--hover">
                    <NavLink
                      className="nav-link-icon text-secondary mb-sm glow-item"
                      href="https://www.facebook.com/pages/category/Cantonese-Restaurant/Great-Wall-Cuisine-114811491884759/"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square text-secondary" />
                      <span className="nav-link-inner--text d-lg-none ml-2 text-secondary fa-lg">
                        Facebook
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Like us on Facebook
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="glow--hover">
                    <NavLink
                      className="nav-link-icon text-secondary mb-sm glow-item"
                      href="https://instagram.com/greatwallcuisineaz?igshid=i66m9hpruj70"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram text-secondary " />
                      <span className="nav-link-inner--text d-lg-none ml-2 text-secondary fa-lg">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default Header;
