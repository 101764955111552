import React from "react";

import Header, { Banner } from "../../common/Header";
import Footer from "../../common/Footer";
import ScrollToTop from "../../common/ScrollToTop";

import { Button } from "reactstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const images = [
  { id: 1, name: "Dim Sum - Small", link: "Menu-1.jpg" },
  { id: 2, name: "Dim Sum - Medium", link: "Menu-2.jpg" },
  { id: 3, name: "Dim Sum - Large/Special", link: "Menu-3.jpg" },
  { id: 4, name: "Entree - Appetizers/Soup", link: "Menu-4.jpg" },
  { id: 5, name: "Entree - Chicken & Bar-B-Que", link: "Menu-5.jpg" },
  { id: 6, name: "Entree - Seafood", link: "Menu-6.jpg" },
  { id: 7, name: "Entree - Vegetables", link: "Menu-7.jpg" },
  { id: 8, name: "Entree - Pork & Beef", link: "Menu-8.jpg" },
  { id: 9, name: "Entree - Chef's Specialties", link: "Menu-9.jpg" },
  { id: 10, name: "Entree - Sizzling Platter & Hot Pot", link: "Menu-10.jpg" },
  { id: 11, name: "Entree - Noodles & Chow Mein", link: "Menu-11.jpg" },
  { id: 12, name: "Entree - Noodles Soup & Fried Rice", link: "Menu-12.jpg" },
  { id: 13, name: "Entree - Luncheon", link: "Menu-13.jpg" },
];

const ScrollMenu = (props) => {
  const refs = props.arr.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleClick = (id) =>
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      offsetTop: -100,
    });

  return (
    <div>
      <div className="btn-wrapper text-center mt-sm">
        {props.arr.map((item, i) => {
          let color;
          let temp;
          if (item.id < 4) {
            color = "primary";
          } else {
            color = "secondary";
          }
          if (item.id === 4) temp = <br key="br"></br>;

          return [
            temp,
            <Button
              key={i}
              className="btn-icon mb-3 mb-sm-0 mt-10"
              onClick={() => handleClick(item.id)}
              color={color}
            >
              <span key={i} className="btn-inner--text text-black">
                {item.name}
              </span>
            </Button>,
          ];
        })}
      </div>

      <ResponsiveMasonry columnsCountBreakPoints={{ 400: 1 }}>
        <Masonry columnsCount={1} gutter="0px">
          {props.arr.map((image) => {
            let temp;
            if (image.id === 4) {
              temp = (
                <Banner
                  path="Menu/Menu-dimsum-BG2.jpg"
                  name="Entrée Menu"
                  min="-3"
                  color="primary"
                ></Banner>
              );
            }

            return [
              temp,
              <div>
                <img
                  key={image.id}
                  ref={refs[image.id]}
                  src={require(`../../../assets/img/Menu/${image.link}`)}
                  style={
                    props.isDesktop
                      ? { width: "100%" }
                      : {
                          height: "100vh",
                          width: "auto",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }
                  }
                  alt=""
                />
              </div>,
            ];
          })}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth < 1537 });
  }

  render() {
    return (
      <>
        <Header></Header>
        <div>
          <Banner
            path="Menu/Menu-dimsum-BG.jpg"
            name="Dim Sum Menu"
            min="-10"
            color="primary"
          ></Banner>
          <ScrollMenu arr={images} isDesktop={this.state.isDesktop} />
        </div>
        <ScrollToTop />
        <Footer></Footer>
      </>
    );
  }
}

export default Menu;
